import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const Spaces = ({ top, left, right, bottom, ...rest }) => {
  const style = useMemo(
    () => ({
      display: 'flex',
      marginLeft: left,
      marginRight: right,
      marginBottom: bottom,
      marginTop: top
    }),
    [top, left, right, bottom]
  );

  return <div style={style} {...rest} />
};

Spaces.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number
};

Spaces.defaultProps = {
  top: null,
  left: null,
  right: null,
  bottom: null
};

export default memo(Spaces);
